<template>
  <div>
    <form @submit.prevent="process()">
      <b-field label="Название группы">
        <b-input v-model="userGroup.name" required></b-input>
      </b-field>
      <b-field  label="Описание">
        <textarea class="textarea" placeholder="" v-model="userGroup.description"></textarea>
      </b-field>
      <h3>Пользователи</h3>
      <div v-if="loading">
        <b-skeleton width="30%" :animated="true"></b-skeleton>

        <b-skeleton width="40%" :animated="true"></b-skeleton>

        <b-skeleton width="80%" :animated="true"></b-skeleton>

        <b-skeleton :animated="true"></b-skeleton>
      </div>
      <div v-else>
        <div
            v-for="user in users"
            :key="user.id">
          <b-checkbox v-model="userGroup.users_ids"
                      :native-value="user.id">
            {{user.first_name}} {{user.last_name}}
          </b-checkbox>
        </div>
      </div>
      <br>
      <h3>Группы доступа</h3>
      <div v-if="groupsLoading">
        <b-skeleton width="30%" :animated="true"></b-skeleton>

        <b-skeleton width="40%" :animated="true"></b-skeleton>

        <b-skeleton width="80%" :animated="true"></b-skeleton>

        <b-skeleton :animated="true"></b-skeleton>
      </div>
      <div v-else>
        <div
            v-for="group in groups"
            :key="group.id">
          <b-checkbox v-model="userGroup.sources_groups_ids"
                      :native-value="group.id">
            {{group.name}}
          </b-checkbox>
        </div>
      </div>
      <br>
      <button type="submit" class="btn btn-primary">{{ payloadProcess === 'create' ? 'Создать' : 'Редактировать' }}</button>
      <router-link :to="{name: 'Groups'}" class="btn btn-outline-warning m-l-10">{{$t('modal.cancel')}}</router-link>
    </form>
    <b-loading :is-full-page="true" v-model="createLoading"></b-loading>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserGroupForm",
  props: ['userGroup', 'payloadProcess'],
  computed: {
    ...mapGetters('users', ['users', 'userLogins']),
    ...mapGetters('source_groups', ['groups'])
  },
  data() {
    return {
      loading: false,
      createLoading: false,
      groupsLoading: false,
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.$store.dispatch('users/getUsersList', {order_type: 'ASC', order_by: 'login'})
          .then(() => {
            this.loading = false;
            this.filteredUsers = this.users;
          })
    },
    loadGroups(){
      this.groupsLoading = true;
      this.$store.dispatch('source_groups/getGroupList', {}).then(() => {
        this.groupsLoading = false
      })
    },
    getFilteredTags(text) {
      this.filteredUsers = this.users.filter((option) => {
        return option.login
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    process() {
      switch (this.payloadProcess) {
        case 'create':
          this.create();
          break;
        case 'edit':
          this.edit();
          break;
      }
    },
    create() {
      this.createLoading = true;
      this.$store.dispatch('access_groups/createUserGroup', this.userGroup).then(() => {
        this.createLoading = false;
        this.$router.push('/settings/user-groups');
      })
    },
    edit() {
      this.createLoading = true;
      this.$store.dispatch('access_groups/editUserGroup', this.userGroup).then(() => {
        this.createLoading = false;
        this.$router.push('/settings/user-groups');
      });
    }
  },
  mounted() {
    this.getUsers();
    this.loadGroups()
  }
}
</script>

<style lang="scss">/*
  h3 {
    margin-bottom: 10px;
    font-weight: 600;
  }

*/</style>